import React, { useMemo } from 'react';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';

import { Box, Flex, Text } from 'theme-ui';
import { BackgroundImage, Container, ImageBlock } from '@sprinklr/shared-lib';
import { RichText } from '@sprinklr/shared-lib';

import { TimelineTemplateProps } from './types';
import { TABLE_VARIANTS, getStyles } from './styles';

const EventTimelineTemplate = ({
  title,
  activities,
  backgroundImage,
  additionalInfo,
  isDarkBackground = false,
  tableVariant = TABLE_VARIANTS.white,
}: TimelineTemplateProps) => {
  const styles = useMemo(
    () => getStyles({ isDarkBackground, tableVariant }),
    [isDarkBackground, tableVariant],
  );
  return (
    <Container containerSx={styles.container} fullWidth>
      {backgroundImage?.asset ? (
        <BackgroundImage image={backgroundImage.asset} />
      ) : null}
      <Text sx={styles.title}>{title}</Text>
      <Box sx={styles.tableContainer}>
        {activities.map(activity => {
          const eventDuration = `${formatInTimeZone(
            new Date(activity.startTime),
            'UTC',
            'hh:mm aaa',
          )} - ${formatInTimeZone(
            new Date(activity.endTime),
            'UTC',
            'hh:mm aaa',
          )}`;
          return (
            <Flex sx={styles.tableRowContainer}>
              <Box sx={styles.tableFirstColumnCellContainer}>
                <Text sx={styles.tableFirstColumnText}>{eventDuration}</Text>
              </Box>
              <Box sx={styles.tableSecondColumnCellContainer}>
                <RichText richTextObject={activity.content} />
              </Box>
            </Flex>
          );
        })}
        {additionalInfo ? (
          <Box sx={styles.additionalInfoContainer}>
            {additionalInfo.additionalInfo}
          </Box>
        ) : null}
      </Box>
    </Container>
  );
};

export default EventTimelineTemplate;
