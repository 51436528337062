import { ThemeUIStyleObject } from 'theme-ui';

export const TABLE_VARIANTS = {
  white: 'WHITE',
  grey: 'GREY',
};

const CONTAINER_STYLES: ThemeUIStyleObject = {
  position: 'relative',
  zIndex: 0,
  backgroundColor: '#f1f0f0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  py: '0px',
  paddingTop: '80px',
};

const BASE_TITLE_STYLES: ThemeUIStyleObject = {
  fontSize: ['22px', '36px'],
  lineHeight: '36px',
  textAlign: 'center',
  fontWeight: 800,
  textTransform: 'uppercase',
};

const ADDITIONAL_INFO_CONTAINER: ThemeUIStyleObject = {
  marginTop: '24px',
  marginBottom: '40px',
  lineHeight: 'normal',
  paddingLeft: ['50px', '280px'],
};

const TABLE_CONTAINER: ThemeUIStyleObject = { marginX: 'auto', width: ['90%', null, '70%'], marginTop: '56px' };
const TABLE_ROW_CONTAINER: ThemeUIStyleObject = { flexDirection: ['column', 'row'], justifyContent: 'center', paddingX: '24px', marginTop: '16px' };

const BASE_TABLE_FIRST_COLUMN_CELL_CONTAINER: ThemeUIStyleObject = {
  display: 'flex',
  justifyContent: ['flex-start', 'center'],
  alignItems: 'center',
  backgroundColor: '#ffffff',
  padding: ['24px 24px 8px 24px', '24px 36px'],
  width: ['100%', '276px'],
};

const tableFirstColumnText: ThemeUIStyleObject = {
  fontSize: ['16px', '20px'],
  lineHeight: '18px',
  fontWeight: 700,
  color: '#00bae9',
};

const BASE_TABLE_SECOND_COLUMN_CELL_CONTAINER: ThemeUIStyleObject = {
  marginLeft: ['0px', '16px'],
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '0%',
  padding: ['0px 24px 0px 24px', '24px 36px 0px 36px'],
  fontSize: '18px',
  lineHeight: '18px',
  backgroundColor: '#ffffff',
  p: {
    color: '#242a34',
    fontSize: ['16px', '20px'],
    lineHeight: 'normal',
    paddingBottom: '24px',
    marginBottom: '0px',
  },
};

export const getStyles = ({
  isDarkBackground = false,
  tableVariant = TABLE_VARIANTS.white,
}): {
  [key: string]: ThemeUIStyleObject;
} => {
  return {
    container: CONTAINER_STYLES,
    title: {
      ...BASE_TITLE_STYLES,
      color: isDarkBackground ? '#ffffff' : '#000000',
    },
    tableContainer: TABLE_CONTAINER,
    tableRowContainer: TABLE_ROW_CONTAINER,
    tableFirstColumnCellContainer: {
      ...BASE_TABLE_FIRST_COLUMN_CELL_CONTAINER,
      backgroundColor: tableVariant === TABLE_VARIANTS.grey ? '#F8F8F8' : '#ffffff',
    },
    tableFirstColumnText,
    tableSecondColumnCellContainer: {
      ...BASE_TABLE_SECOND_COLUMN_CELL_CONTAINER,
      backgroundColor: tableVariant === TABLE_VARIANTS.grey ? '#F8F8F8' : '#ffffff',
    },
    additionalInfoContainer: ADDITIONAL_INFO_CONTAINER,
  };
};
